import axios from "axios";
import urls from "../config/urls";
import {snackbarActions} from "../store/storeIndex";


const ApiDeleteRecipe = (id, dispatch, navigate) => {
    const authToken = localStorage.getItem("token");

    axios.delete(urls.deleteRecipe,
        {
            data: {id: id},
            headers: {'Authorization': authToken}
        })
        .then(res => {
            dispatch(snackbarActions.severity("success"));
            dispatch(snackbarActions.openSnackbar());
            dispatch(snackbarActions.message(res.data.status_message));
            navigate("/", {replace: true});
            window.location.reload();
        })
        .catch(error => {
            dispatch(snackbarActions.severity("error"));
            dispatch(snackbarActions.openSnackbar());
            dispatch(snackbarActions.message("Fehlgeschlagen"));
            console.log(error)
        })

}

export default ApiDeleteRecipe;