import {configureStore, createSlice} from "@reduxjs/toolkit"


const initialLoginState = {loginStatus: false};
const initialRecipesState = {data: [], furtherEntries: false};
const initialSnackbarState = {open: false, message: "", severity: "error"}
const initialDetailsState = {
    ingredients: [],
    description: "",
    recipeName: "",
    userName: "",
    voting: 0,
    userId: "",
    allowEdit: false
}
const initialVotingState = {available: 0, used: 0, votes: []}
const initialUserState = {currentUserId: "", currentUsername: ""}
const initialRecipesRatedState = {recipeIds: []}


const loginSlice = createSlice({
    name: "loginState",
    initialState: initialLoginState,
    reducers: {
        login(state) {
            state.loginStatus = true;
        },
        logout(state) {
            state.loginStatus = false;
        }
    }
})

const recipesSlice = createSlice({
    name: "recipesState",
    initialState: initialRecipesState,
    reducers: {
        loadData(state, action) {
            state.data = action.payload.recipes;
            for (let i = 0; i < state.data.length; i++) {
                if (!state.data[i].voting) {
                    state.data[i].voting = 0;
                }
            }
            state.furtherEntries = action.payload.further_entries;
        },
    }
})

const snackbarSlice = createSlice({
    name: "snackbarState",
    initialState: initialSnackbarState,
    reducers: {
        severity(state, action) {
            state.severity = action.payload;
        },
        openSnackbar(state) {
            state.open = true;
        },
        closeSnackbar(state) {
            state.open = false;
        },
        message(state, action) {
            state.message = action.payload;
        },
    }
})

const detailsSlice = createSlice({
    name: "detailsState",
    initialState: initialDetailsState,
    reducers: {
        setDetails(state, action) {
            state.ingredients = action.payload.ingredients;
            console.log(state.ingredients)
            state.description = action.payload.description;
            state.userName = action.payload.author;
            state.userId = action.payload.author_id;
            state.allowEdit = action.payload.edit_allowed;
            state.recipeName = action.payload.recipe_name;
            (!action.payload.voting) ? state.voting = 0 : state.voting = action.payload.voting;
        },
    }
})

const votingSlice = createSlice({
    name: "votingState",
    initialState: initialVotingState,
    reducers: {
        setAvailable(state, action) {
            state.available = action.payload;
        },
        vote(state) {
            state.used = state.votes.length;
        },
        addRecipeVote(state, action) {
            state.votes.push(action.payload);
        },
        cancelVote(state) {
            state.votes = [];
            state.used = 0;
        },
    }
})

const userSlice = createSlice({
    name: "userState",
    initialState: initialUserState,
    reducers: {
        setUser(state, action) {
            state.currentUserId = action.payload.id;
            state.currentUsername = action.payload.username;
        }
    }
})

const recipesRatedSlice = createSlice({
    name: "recipesRatedState",
    initialState: initialRecipesRatedState,
    reducers: {
        setRecipeIds(state, action) {
            state.recipeIds = action.payload;
        }
    }
})

const store = configureStore({
    reducer: {
        login: loginSlice.reducer,
        recipes: recipesSlice.reducer,
        snackbar: snackbarSlice.reducer,
        details: detailsSlice.reducer,
        voting: votingSlice.reducer,
        user: userSlice.reducer,
        recipesRated: recipesRatedSlice.reducer,
    }
});

export const snackbarActions = snackbarSlice.actions;
export const loginActions = loginSlice.actions;
export const recipeActions = recipesSlice.actions;
export const detailsActions = detailsSlice.actions;
export const votingActions = votingSlice.actions;
export const userActions = userSlice.actions;
export const recipesRatedActions = recipesRatedSlice.actions;
export default store;