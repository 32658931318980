import axios from "axios";
import Urls from "../config/urls";
import {snackbarActions} from "../store/storeIndex";


const ApiRegister = (username, email, password, dispatch, navigate) => {
    axios.post(Urls.register, {
        e_mail: email,
        username: username,
        password: password
    })
        .then(res => {
            dispatch(snackbarActions.severity("success"));
            dispatch(snackbarActions.openSnackbar());
            dispatch(snackbarActions.message(res.data.statusText));
            navigate("/login", {replace: true});
        })
        .catch(function (error) {
            let message = error.response.data.message;
            if (error.response.data.e_mail && error.response.data.username) {
                message = "E-mail und Benutzername bereits vergeben";
            } else if (error.response.data.e_mail) {
                message = "E-mail bereits vergeben";
            } else if (error.response.data.username) {
                message = "Username bereits vergeben";
            }
            dispatch(snackbarActions.severity("error"));
            dispatch(snackbarActions.openSnackbar());
            dispatch(snackbarActions.message(message));
        });
}

export default ApiRegister;