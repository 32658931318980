import UseDomain from "../hooks/useDomain"

const domain = UseDomain();

//object for saving urls to APIs necessary in development
const Urls = {
    login: domain + "/auth/token",
    register: domain + "/auth/register",
    recipes: domain + "/recipe/list-recipes",
    details: domain + "/recipe/recipe_details/",
    currentUser: domain + "/auth/get_current_user", //user who is currently logged in
    availableVotes: domain + "/vote/votes_per_user",
    postVotes: domain + "/vote/submit_votes", //api for posting which recipe received which voting
    postRecipe: domain + "/recipe/submit_recipe",
    updateRecipe: domain + "/recipe/edit_recipe",
    deleteRecipe: domain + "/recipe/delete_recipe",
    recipesRated: domain + "/vote/recipes_rated",
}

export default Urls;