import {Popper, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles"
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiAutocomplete-listbox": {
            backgroundColor: theme.palette.primary.dark,
            "& :hover": {backgroundColor: theme.palette.primary.light},
        },
        "& .MuiInputBase-root": {
            color: theme.text.primary,
        },
        zIndex: 0,
    },
}))

const CustomPopper = function (props) {
    const classes = useStyles();
    return <Popper {...props} className={classes.root}/>
};

export default function UomSelect({...defaultProps}) {
    // fetch units of measurement from JSON file, use them as suggestions for autocomplete
    const unitSuggestions = require("../../data/uom/UOM.json");
    let uomArr = [];
    for (let i in unitSuggestions.uom) {
        uomArr.push(unitSuggestions.uom[i]);
    }

    const filter = createFilterOptions();
    const classes = useStyles();

    const FilterOptions = (options, params) => {
        const filtered = filter(options, params);
        const {inputValue} = params;
        const isExisting = options.some(() => inputValue === options.label);
        if (!isExisting) {
            filtered.push({
                inputValue,
                label: `Add "${inputValue}"`,
            });
            return filtered;
        }
    }

    const GetOptionLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        // Add "xyz" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }
        // Regular option
        return option.label;
    }

    return (
        <Autocomplete
            {...defaultProps}
            className={classes.root}
            sx={{
                width: "45%",
                padding: 3,
            }}
            color={"secondary"}
            size={"small"}
            PopperComponent={CustomPopper}
            renderInput={(params) => <TextField {...params} label={"Einheit"}/>}
            options={uomArr}
            filterOptions={FilterOptions}
            getOptionLabel={GetOptionLabel}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            value={defaultProps.value}
            onChange={defaultProps.onChange}
            selectOnFocus
            clearOnBlur
            freeSolo
            forcePopupIcon
        />
    );
}
