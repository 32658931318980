import {Box, Button, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import VotingCard from "../../components/votingCard/votingCard"
import ApiDetails from "../../api/apiDetails"
import ApiCurrentUser from "../../api/apiCurrentUser";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from "../../components/deleteDialog/deleteDialog";
import ApiRecipesRated from "../../api/apiRecipesRated";
import ApiAvailableVotes from "../../api/apiAvailableVotes";


const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.text.primary,
        fontSize: "17pt"
    }
}));

export default function DetailsPage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.login.loginStatus);
    const currentUsername = useSelector(state => state.user.currentUsername);
    const [allowEdit, setAllowEdit] = useState(false);
    const [open, setOpen] = useState(false)

    // get recipe id from URL
    const url = window.location.href
    const id = url.substring(url.lastIndexOf('/') + 1);
    const editUrl = "/recipe/" + id;

    useEffect(() => {
        ApiDetails(id, dispatch);
        if(isLoggedIn) {
            ApiCurrentUser(dispatch);
            ApiRecipesRated(dispatch);
            ApiAvailableVotes(dispatch);
        }
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let details = {
        ingredients: useSelector(state => state.details.ingredients),
        description: useSelector(state => state.details.description),
        userName: useSelector(state => state.details.userName),
        recipeName: useSelector(state => state.details.recipeName),
        voting: useSelector(state => state.details.voting),
    }
    if (!details.ingredients) {
        details.ingredients = [];
    }
    if (!details.description) {
        details.description = "";
    }
    if (!details.userName) {
        details.userName = "";
    }
    if (!details.recipeName) {
        details.recipeName = "";
    }
    if (!details.voting) {
        details.voting = 0;
    }

    useEffect(() => {
        currentUsername === details.userName ? setAllowEdit(true) : setAllowEdit(false);
    }, [currentUsername])

    return (
        <Box
            sx={{
                width: "80%",
                margin: "auto"
            }}>
            <Typography
                variant={"h3"}
                textAlign={"center"}
            >
                {details.recipeName}
            </Typography>
            <Typography
                variant={"h4"}
                textAlign={"center"}
                gutterBottom
            >
                von {details.userName}
            </Typography>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: 'flex-end',
            }}>
                {isLoggedIn && allowEdit &&
                    <Box display={"flex"}>
                        <Button
                            sx={{margin: 1}}
                            size={"small"}
                            variant={"outlined"}
                            color={"secondary"}
                            endIcon={<EditIcon/>}
                            href={editUrl}
                        >
                            bearbeiten
                        </Button>
                        <Button
                            sx={{margin: 1}}
                            size={"small"}
                            variant={"outlined"}
                            color={"error"}
                            endIcon={<DeleteIcon/>}
                            onClick={handleClickOpen}
                        >
                            löschen
                        </Button>
                    </Box>
                }
            </Box>
            <Typography variant={"h5"}>
                Zutaten:
            </Typography>
            <Typography
                className={classes.text}
                gutterBottom
            >
                {details.ingredients.map((ingredient) => {
                    return (
                        <li key={ingredient.amount + ingredient.unit + ingredient.ingredient_name}>
                            {ingredient.amount}
                            {" "}
                            {ingredient.unit}
                            {" "}
                            {ingredient.ingredient_name}
                        </li>
                    )
                })}
            </Typography>
            <Typography variant={"h5"}>
                Zubereitung:
            </Typography>
            <Typography className={classes.text}>
                {details.description}
            </Typography>
            {isLoggedIn &&
                <VotingCard
                    recipeId={id}
                    voting={details.voting}
                />}
            <DeleteDialog
                open={open}
                handleClose={handleClose}
                recipeId={id}
                recipeName={details.recipeName}
            />
        </Box>
    )
}