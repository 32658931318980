import {useEffect, useState} from "react";
import {
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import PropTypes from "prop-types";
import RecipesTablePaginationActions from "./recipesTablePaginationActions"
import RecipesTableEdit from "./recipesTableEdit"
import {useDispatch, useSelector} from "react-redux";
import ApiRecipes from "../../api/apiRecipes";
import ApiCurrentUser from "../../api/apiCurrentUser";
import VotingCardTable from "../votingCard/votingCardTable";
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    tableHead: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.dark,
        fontSize: "17pt"
    },
    tableCell: {
        color: theme.text.primary,
        fontSize: "14pt"
    },
    tablePagination: {
        color: theme.text.primary,
        "& .MuiSelect-icon": {
            color: theme.text.primary,
        },
        "& .MuiTablePagination-toolbar": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    selectDropdown: {
        color: theme.text,
        backgroundColor: theme.palette.primary.dark
    },
    menuItem: {
        "&:hover": {
            backgroundColor: theme.palette.primary.light
        }
    },
}));

RecipesTablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

export default function RecipesTable(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.login.loginStatus);
    const ratedRecipes = useSelector(state => state.recipesRated.recipeIds);
    const votesAvailable = useSelector(state => state.voting.available);
    const votesUsed = useSelector(state => state.voting.used);
    const recipesVoted = useSelector(state => state.voting.votes);
    const currentUsername = useSelector(state => state.user.currentUsername);

    useEffect(() => {
        ApiRecipes(page * rowsPerPage, rowsPerPage, dispatch);
        if (isLoggedIn) {
            ApiCurrentUser(dispatch);
        }
    }, [rowsPerPage, page, dispatch])

    const allowEditFunction = (username) => {
        let allowEdit = false;
        if (currentUsername === username) {
            allowEdit = true;
        }
        return allowEdit;
    }

    const isEnabledFunction = (id) => {
        let isDisabled = false;
        if (ratedRecipes.recipe_ids) {
            for (let i = 0; i < ratedRecipes.recipe_ids.length; i++) {
                if (id === ratedRecipes.recipe_ids[i]) {
                    isDisabled = true;
                }
            }
        }
        if (votesAvailable === votesUsed || votesAvailable === 0) {
            isDisabled = true;
        }
        if (recipesVoted) {
            for (let i = 0; i < recipesVoted.length; i++) {
                if (id === recipesVoted[i].recipe_id) {
                    isDisabled = true;
                }
            }
        }
        return isDisabled;
    }

    let rows = useSelector(state => state.recipes.data)
    if (!rows) {
        rows = [];
    }
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formatDate = (dateString) => {
        const date = moment(dateString).format("DD.MM.YYYY");
        date.toString();
        return date;
    }

    return (
        <TableContainer
            component={Paper}
            sx={{
                width: "80%",
                backgroundColor: "primary.light",
                margin: "auto",
            }}>
            <Table sx={{width: "100%",}} aria-label={"recipe table"}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead}>Rezept</TableCell>
                        {isLoggedIn &&
                            <TableCell className={classes.tableHead}> </TableCell>}
                        {isLoggedIn &&
                            <TableCell className={classes.tableHead}>Bewertung</TableCell>}
                        {isLoggedIn &&
                            <TableCell className={classes.tableHead}>Punktzahl</TableCell>}
                        <TableCell
                            className={classes.tableHead}
                            align={"left"}
                        >Benutzer</TableCell>
                        <TableCell
                            className={classes.tableHead}
                            align={"right"}
                        >erstellt am</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                    ).map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell
                                align={"left"}
                                className={classes.tableCell}
                            >
                                <Link
                                    className={classes.tableCell}
                                    href={"/details/" + row.id}
                                >
                                    {row.recipe_name}
                                </Link>
                            </TableCell>
                            {isLoggedIn &&
                                <TableCell>
                                    {allowEditFunction(row.username) &&
                                        <RecipesTableEdit
                                            id={row.id}
                                            recipeName={row.recipe_name}
                                            setOpen={props.setOpen}
                                            setDeleteRecipeName={props.setDeleteRecipeName}
                                            setDeleteRecipeId={props.setDeleteRecipeId}
                                        />}
                                </TableCell>}
                            {isLoggedIn &&
                                <TableCell>
                                    <VotingCardTable
                                        id={row.id}
                                        disabled={isEnabledFunction(row.id)}
                                        isEnabledFunction={isEnabledFunction}
                                    />
                                </TableCell>}
                            {isLoggedIn &&
                                <TableCell className={classes.tableCell}>
                                    {row.voting}
                                </TableCell>}
                            <TableCell
                                align={"left"}
                                className={classes.tableCell}
                            >{row.username}</TableCell>
                            <TableCell
                                align={"right"}
                                className={classes.tableCell}
                            >
                                {formatDate(row.created_on)}
                            </TableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{height: 59 * emptyRows}}>
                            <TableCell colSpan={6}/>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            className={classes.tablePagination}
                            classes={{menuItem: classes.menuItem}}
                            rowsPerPageOptions={[5, 10, 25, 100]}
                            colSpan={7}
                            count={rows.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            SelectProps={{
                                MenuProps: {
                                    classes: {paper: classes.selectDropdown}
                                },
                                inputProps: {
                                    "aria-label": "Zeilen pro Seite:"
                                },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={RecipesTablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}



