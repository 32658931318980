import axios from "axios";
import Urls from "../config/urls"
import {snackbarActions} from "../store/storeIndex";


const ApiPostVotes = (dispatch, votedRecipes) => {
    const authToken = localStorage.getItem("token");

    axios.post(Urls.postVotes, {votes_list: votedRecipes}, {headers: {'Authorization': authToken}})
        .then(res => {
            dispatch(snackbarActions.openSnackbar());
            dispatch(snackbarActions.message("Bewertung abgegeben"))
            dispatch(snackbarActions.severity(res.data.status))
        })
        .catch(error => {
            dispatch(snackbarActions.openSnackbar());
            dispatch(snackbarActions.message("Fehlgeschlagen"))
            dispatch(snackbarActions.severity("error"))
            console.log(error)
        })
}

export default ApiPostVotes