import {AppBar, Box, Button, ButtonGroup} from "@mui/material";
import {useDispatch, useSelector} from "react-redux"
import {loginActions} from "../../store/storeIndex";


const Navbar = () => {
    const dispatch = useDispatch();
    const logInStatus = useSelector(state => state.login.loginStatus);

    function handleLogOut() {
        dispatch(loginActions.logout());
        localStorage.removeItem("token");
    }

    return (
        <Box sx={{
            width: "100%",
            position: "fixed",
            alignItems: "center"
        }}>
            <AppBar
                position={"fixed"}
                sx={{backgroundColor: "primary.dark"}}
            >
                {logInStatus && (
                    <ButtonGroup
                        variant={"text"}
                        color={"secondary"}
                        size={"large"}
                        sx={{margin: "auto"}}
                    >
                        <Button href={"/"}>Alle Rezepte</Button>
                        <Button href={"/recipe"}>Rezept teilen</Button>
                        <Button href={"/user"}>Profil</Button>
                        <Button
                            onClick={handleLogOut}
                            href={"/"}
                        >Abmelden</Button>
                    </ButtonGroup>
                )}
                {(logInStatus === false) && (
                    <ButtonGroup
                        variant={"text"}
                        color={"secondary"}
                        size={"large"}
                        sx={{margin: "auto"}}
                    >
                        <Button href={"/"}>Alle Rezepte</Button>
                        <Button href={"/login"}>Anmelden</Button>
                    </ButtonGroup>
                )}
            </AppBar>
        </Box>
    )
}
export default Navbar;