import axios from "axios";
import Urls from "../config/urls";
import {recipesRatedActions} from "../store/storeIndex";


const ApiRecipesRated = (dispatch) => {
    const authToken = localStorage.getItem("token");

    axios.get(Urls.recipesRated,{headers: {'Authorization': authToken}})
        .then(res => {
            dispatch(recipesRatedActions.setRecipeIds(res.data))
        })
        .catch(error => {
            console.log(error)
        })
}

export default ApiRecipesRated;