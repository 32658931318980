import axios from "axios";
import Urls from "../config/urls";
import {votingActions} from "../store/storeIndex"


const ApiAvailableVotes = (dispatch) => {
    const authToken = localStorage.getItem("token");

    return axios.get(Urls.availableVotes, {headers: {'Authorization': authToken}})
        .then(res => {
            dispatch(votingActions.setAvailable(res.data.remaining_votes))
        })
        .catch(error => {
            console.log(error.response)
        })
}

export default ApiAvailableVotes;