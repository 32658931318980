import axios from "axios";
import Urls from "../config/urls";
import {snackbarActions} from "../store/storeIndex";


const ApiEditRecipe = (id, recipeName, description, ingredients, dispatch, navigate) => {
    const authToken = localStorage.getItem("token");
    let ingredientsList = [];

    // adjust ingredient array to the right format for api
    for (let i = 0; i < ingredients.length; i++) {
        let ingredient = {
            amount: ingredients[i].amount ? ingredients[i].amount : undefined,
            unit: ingredients[i].unit ? ingredients[i].unit : undefined,
            ingredient_name: ingredients[i].ingredientName,
            ingredient_id: ingredients[i].id ? ingredients[i].id : undefined
        };
        ingredientsList.push(ingredient)
    }

    axios.patch(Urls.updateRecipe, {
            id: id,
            recipe_name: recipeName,
            description: description,
            ingredients: ingredientsList,
        },
        {headers: {'Authorization': authToken}}
    )
        .then(res => {
            // jump to details page after editing recipe
            const recipeId = res.data.recipe_id;
            navigate("/details/" + recipeId);
            dispatch(snackbarActions.openSnackbar());
            dispatch(snackbarActions.message("Rezept bearbeitet"))
            dispatch(snackbarActions.severity("success"))
        })
        .catch(error => {
            dispatch(snackbarActions.openSnackbar());
            dispatch(snackbarActions.message("Fehlgeschlagen"))
            dispatch(snackbarActions.severity("error"))
        })
}

export default ApiEditRecipe;