import * as yup from "yup";


const lowerCaseRegex = /(?=.*[a-z])/
const upperCaseRegex = /(?=.*[A-Z])/
const numericalRegex = /(?=.*[0-9])/
const specialCharRegex = /(?=.*[^0-9a-zA-Z])/

const RegisterSchema = yup.object().shape({
    email: yup.string()
        .lowercase()
        .email("muss eine gültige E-Mail Adresse sein")
        .required("Erforderlich"),
    username: yup.string()
        .required("Erforderlich"),
    password: yup.string()
        .min(8, "Passwort muss muss mindestens 8 Zeichen lang sein!")
        .matches(lowerCaseRegex, "Passwort muss mindestens einen Kleinbuchstaben beinhalten!")
        .matches(upperCaseRegex, "Passwort muss mindestens einen Großbuchstaben beinhalten!")
        .matches(numericalRegex, "Passwort muss mindestens eine Zahl beinhalten")
        .matches(specialCharRegex, "Passwort muss mindestens ein Sonderzeichen beinhalten!")
        .required("Erforderlich!"),
    confirmPassword: yup.string()
        .oneOf([yup.ref("password")], "Muss mit Passwort übereinstimmen!")
        .required("Erforderlich!")
}).required();
export default RegisterSchema;