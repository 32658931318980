import {makeStyles} from "@mui/styles";
import {Box, IconButton} from "@mui/material";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import {useDispatch, useSelector} from "react-redux";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import {votingActions} from "../../store/storeIndex"
import {useEffect} from "react";
import ApiRecipesRated from "../../api/apiRecipesRated";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.light,
        color: theme.text.primary,
        alignItems: "center",
        display: 'inline-flex'
    },
    icon: {
        color: theme.text.primary,
        size: "80pt"
    },
    text: {
        color: theme.text.primary,
        fontSize: "13pt"
    }
}))

export default function VotingCardTable(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const ratedRecipes = useSelector(state => state.recipesRated.recipeIds)

    useEffect(() => {
        ApiRecipesRated(dispatch)
    }, [])

    useEffect(() => {
        props.isEnabledFunction(props.id)
    }, [ratedRecipes])

    function downvote() {
        dispatch(votingActions.addRecipeVote({
            vote: -1,
            recipe_id: props.id,
        }))
        dispatch(votingActions.vote())
    }

    function upvote() {
        dispatch(votingActions.addRecipeVote({
            vote: 1,
            recipe_id: props.id,
        }))
        dispatch(votingActions.vote())
    }

    return (
        <Box className={classes.root}>
            <IconButton
                className={classes.icon}
                aria-label={"downvote"}
                onClick={downvote}
                disabled={props.disabled}
            >
                <RemoveCircleTwoToneIcon fontSize={"medium"}/>
            </IconButton>
            <IconButton
                className={classes.icon}
                aria-label={"upvote"}
                onClick={upvote}
                disabled={props.disabled}
            >
                <AddCircleTwoToneIcon fontSize={"medium"}/>
            </IconButton>
        </Box>
    )
}