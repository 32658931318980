import {Box, ThemeProvider, Toolbar} from "@mui/material";
import useTheme from "./config/theme"
import MyRoutes from "./config/routes"
import Navbar from "./components/navbar/navbar";
import Layout from "./config/layout";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loginActions} from "./store/storeIndex";


function App() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const loginStatus = useSelector(state => state.login.loginStatus);
    useEffect(() => {
        if (localStorage.getItem("token")) {
            dispatch(loginActions.login())
        }
    }, [loginStatus, dispatch])

    return (
        <ThemeProvider theme={theme}>
            <Toolbar/>
            <Navbar/>
            <Layout>
                <Box className={"App"}>
                    <MyRoutes/>
                </Box>
            </Layout>
        </ThemeProvider>
    );
}

export default App
