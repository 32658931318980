import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import {IconButton} from '@mui/material';
import {snackbarActions} from "../../store/storeIndex";
import {useDispatch, useSelector} from "react-redux";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomSnackbar() {
    const dispatch = useDispatch();
    let severity = useSelector(state => state.snackbar.severity);
    const message = useSelector(state => state.snackbar.message);
    const open = useSelector(state => state.snackbar.open)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            dispatch(snackbarActions.closeSnackbar())
        }
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (

        <Stack spacing={2} sx={{width: '100%'}}>
            <Snackbar
                open={open}
                autoHideDuration={2500}
                onClose={handleClose}
                action={action}
            >
                <Alert onClose={handleClose}
                       severity={severity}
                       sx={{width: '100%'}}
                >
                    {message}
                </Alert>
            </Snackbar>
        </Stack>

    );
}
