import {createTheme} from "@mui/material";


function useTheme() {
    return (
        createTheme({
            palette: {
                primary: {
                    main: "#282c34",
                    dark: "#21252b",
                    light: "#2c313a",
                },
                secondary: {
                    main: "#ecffb3",
                    dark: "#e6ff99",
                    light: "#f2ffcc",
                }
            },
            text: {
                primary: "#e2e5e9",
            },
            background: {
                paper: "#2c313a",
                default: "#282c34",
            },
            typography: {
                allVariants: {color: "#ecffb3"},
                fontFamily: "'Josefin Sans', sans-serif",
            },
        })
    )
}

export default useTheme


