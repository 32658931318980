import {Box, Button, Typography} from "@mui/material";
import UomSelect from "../../components/uomSelect/uomSelect";
import * as React from 'react';
import {useEffect, useState} from 'react';
import TextFieldStyled from "../../components/textFieldStyled/textFieldStyled";
import IngredientsDisplay from "../../components/ingredientsDisplay/ingredientsDisplay";
import {useFormik} from "formik";
import SubmitSchema from "../../validations/submitRecipeValidation/submitRecipeValidation";
import ApiSubmit from "../../api/apiSubmit";
import {makeStyles} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ApiDetails from "../../api/apiDetails";
import ApiEditRecipe from "../../api/apiEditRecipe"


const useStyles = makeStyles(theme => ({
    errorMessage: {
        color: theme.palette.error.light,
        fontSize: "14pt"
    },
}))

export default function SubmitRecipe() {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const editRecipe = useSelector(state => state.details);
    const [ingredients, setIngredients] = useState([]);
    const [title, setTitle] = useState("Teile Dein Rezept:");
    const [emptyIngredientName, setEmptyIngredientName] = useState(false);
    const emptyIngredientNameError = "Name der Zutat darf nicht leer sein!";

    const url = window.location.href
    const id = url.substring(url.lastIndexOf('/') + 1);

    //------ initialize formik -----------------------------------------------------------------------------------------
    const formik = useFormik({
        initialValues: {
            recipeName: "",
            recipeDescription: "",
            amount: "",
            unit: null,
            ingredientName: "",
            id: null
        },
        validationSchema: SubmitSchema,
        onSubmit: (values) => {
            if (id !== "recipe") {
                ApiEditRecipe(id, values.recipeName, values.recipeDescription, ingredients, dispatch, navigate);
            } else {
                ApiSubmit(values.recipeName, values.recipeDescription, ingredients, dispatch, navigate);
                formik.resetForm();
            }
        }
    })

    //----- enable edit mode if id exists ------------------------------------------------------------------------------
    useEffect(() => {
        if (id !== "recipe") {
            ApiDetails(id, dispatch);
            setTitle("Bearbeite Dein Rezept:");
        }
    }, [])

    const setFormikValues = () => {
        formik.setFieldValue("recipeName", editRecipe.recipeName);
        formik.setFieldValue("recipeDescription", editRecipe.description);
        let ingredientsArr = [];
        for (let i = 0; i < editRecipe.ingredients.length; i++) {
            let ingredient = {
                ingredientName: editRecipe.ingredients[i].ingredient_name,
                amount: editRecipe.ingredients[i].amount ? editRecipe.ingredients[i].amount : null,
                unit: editRecipe.ingredients[i].unit ? editRecipe.ingredients[i].unit : null,
                id: editRecipe.ingredients[i].ingredient_id ? editRecipe.ingredients[i].ingredient_id : undefined,
            }
            ingredientsArr.push(ingredient)
        }
        setIngredients(ingredientsArr);
    }

    useEffect(() => {
        setFormikValues();
    }, [editRecipe])

    //----- logic for unit input ---------------------------------------------------------------------------------------
    const handleUnit = (event, newUnit) => {
        if (typeof newUnit === 'string') {
            formik.setFieldValue("unit", {
                label: newUnit,
            });
        } else if (newUnit === null) {
            formik.setFieldValue("unit", "");
        } else if (newUnit && newUnit.inputValue) {
            formik.setFieldValue("unit", newUnit.inputValue)
        } else {
            formik.setFieldValue("unit", newUnit.label);
        }
    }

    //----- logic for ingredients display ------------------------------------------------------------------------------
    function handleAddIngredient() {
        if(formik.values.ingredientName === ""){
            setEmptyIngredientName(true);
        } else {
            let ingredientInput = {
                amount: formik.values.amount ? formik.values.amount : null,
                unit: formik.values.unit ? formik.values.unit : "",
                ingredientName: formik.values.ingredientName,
                id: formik.values.id ? formik.values.id : null
            };
            setIngredients([...ingredients, ingredientInput]);
            formik.resetForm({
                values: {
                    recipeName: formik.values.recipeName,
                    recipeDescription: formik.values.recipeDescription,
                    ingredients: formik.values.ingredients,
                    amount: "",
                    unit: null,
                    ingredientName: ""
                }
            });
        }
    }

    function handleDeleteIngredient(ingredient) {
        let ingredientsArr = [...ingredients];
        const i = ingredientsArr.indexOf(ingredient);
        ingredientsArr.splice(i, 1);
        setIngredients(ingredientsArr);
    }

    function handleEditIngredient(ingredient) {
        handleDeleteIngredient(ingredient)

        ingredient.id ?
            formik.setFieldValue("id", ingredient.id) :
            formik.setFieldValue("id", null);
        ingredient.amount ?
            formik.setFieldValue("amount", ingredient.amount) :
            formik.setFieldValue("amount", "");
        ingredient.unit ?
            formik.setFieldValue("unit", ingredient.unit) :
            formik.setFieldValue("unit", "");
        formik.setFieldValue("ingredientName", ingredient.ingredientName);
    }

    return (
        <Box>
            <Box
                component={"form"}
                noValidate
                autoComplete={"off"}
                onSubmit={formik.handleSubmit}
                sx={{
                    width: "55%",
                    textAlign: "left",
                    margin: "auto",
                }}>
                <Typography
                    variant={"h3"}
                    textAlign={"center"}
                    gutterBottom
                >{title}</Typography>
                <br/>
                <TextFieldStyled
                    id={"recipeName"}
                    label={"Rezept Titel"}
                    fullWidth={true}
                    value={formik.values.recipeName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.recipeName && formik.errors.recipeName ?
                    <Typography className={classes.errorMessage}>
                        {formik.errors.recipeName}
                    </Typography> : null}<br/>
                <IngredientsDisplay
                    value={ingredients}
                    onDelete={handleDeleteIngredient}
                    onEdit={handleEditIngredient}
                /> <br/>
                {/*Ingredient Input:---------------------------------------------------------------------------------*/}
                <Box sx={{display: 'inline-flex'}}>
                    <TextFieldStyled
                        sx={{width: "30%",}}
                        type={"number"}
                        inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                        id={"amount"}
                        label={"Menge"}
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                    />
                    <UomSelect
                        value={formik.values.unit}
                        onChange={handleUnit}
                    />
                    <TextFieldStyled
                        sx={{
                            width: "40%",
                            marginRight: 5
                        }}
                        id={"ingredientName"}
                        label={"Name der Zutat"}
                        value={formik.values.ingredientName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <Button
                        sx={{margin: "auto"}}
                        variant={"contained"}
                        color={"secondary"}
                        onClick={handleAddIngredient}
                    >hinzufügen</Button><br/>
                {/*--------------------------------------------------------------------------------------------------*/}
                </Box>
                {formik.touched.ingredientName && emptyIngredientName ?
                    <Typography className={classes.errorMessage}>
                        {emptyIngredientNameError}
                    </Typography> : null}<br/>
                <TextFieldStyled
                    id={"recipeDescription"}
                    label={"Zubereitung"}
                    multiline
                    rows={"4"}
                    fullWidth={true}
                    value={formik.values.recipeDescription}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.recipeDescription && formik.errors.recipeDescription ?
                    <Typography className={classes.errorMessage}>
                        {formik.errors.recipeDescription}
                    </Typography> : null}<br/>
                <Button
                    type={"submit"}
                    variant={"contained"}
                    color={"secondary"}
                >Rezept teilen</Button><br/>
            </Box>
        </Box>
    )
}
