import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import SubmitRecipe from "../pages/submitRecipe/SubmitRecipe"
import ShowRecipes from "../pages/showRecipes/ShowRecipes";
import User from "../pages/user/User";
import NotFound from "../pages/notFound/NotFound";
import DetailsPage from "../pages/detailsPage/detailsPage";
import LogIn from "../pages/logIn/logIn";
import CreatAccount from "../pages/createAccount/createAccount";


function MyRoutes() {
    return (
        <Router>
            <Routes>
                <Route exact path={"/"} element={<ShowRecipes/>}/>
                <Route exact path={"/recipe"} element={<SubmitRecipe/>}>
                    <Route path={":recipeId"} element={<SubmitRecipe/>}/>
                </Route>
                <Route exact path={"/user"} element={<User/>}/>
                <Route exact path={"/login"} element={<LogIn/>}/>
                <Route exact path={"/newuser"} element={<CreatAccount/>}/>
                <Route exact path={"/details"} element={<DetailsPage/>}>
                    <Route path={":detailsId"} element={<DetailsPage/>}/>
                </Route>
                <Route exact path={"/*"} element={<NotFound/>}/>
            </Routes>
        </Router>
    );
}

export default MyRoutes