import {Box, Typography} from "@mui/material";


function NotFound() {
    return (
        <Box sx={{
            textAlign: "center",
            padding: 15,
        }}>
            <Typography variant={"h3"}>Diese Seite existiert nicht!</Typography><br/>
        </Box>
    );
}

export default NotFound