import {makeStyles} from "@mui/styles";
import Navbar from "../components/navbar/navbar";
import CustomSnackbar from "../components/customSnackbar/customSnackbar";
import * as React from "react";


const useStyles = makeStyles({
    page: {
        backgroundColor: "#282c34",
        width: "100%",
        height: "100%",
    },
})
export default function Layout({children}) {
    const classes = useStyles()
    return (
        <div>
            <Navbar/>
            <div className={classes.page}>
                {children}
            </div>
            <CustomSnackbar/>
        </div>
    )
}