import {Box, Button, Dialog, DialogContent, DialogTitle} from '@mui/material';
import {useEffect, useState} from "react";
import ApiDeleteRecipe from "../../api/apiDeleteRecipe";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@mui/styles"
import TextFieldStyled from "../textFieldStyled/textFieldStyled";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.light,
        color: theme.text.primary,
        "& .MuiDialog-paper": {
            color: theme.text.primary,
            backgroundColor: theme.palette.primary.light,
        }
    },
}))

export default function DeleteDialog(props) {
    const [recipeName, setRecipeName] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChangeRecipeName = (event) => {
        setRecipeName(event.target.value)
    };

    const handleDelete = () => {
        ApiDeleteRecipe(props.recipeId, dispatch, navigate)
        props.handleClose();
    }

    const isDisabledFunction = () => {
        if (recipeName === props.recipeName) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true)
        }
    }

    useEffect(() => {
        isDisabledFunction();
    }, [recipeName, props.recipeName])

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            className={classes.root}
        >
            <DialogTitle color={"error"}>Wollen Sie Ihr Rezept "{props.recipeName}" wirklich löschen?</DialogTitle>
            <DialogContent>
                <DialogContent>
                    Bitte geben Sie den Titel ein, um das Rezept zu löschen.
                </DialogContent>
                <TextFieldStyled
                    id={"recipeTitle"}
                    label={"Recipe title"}
                    noValidate
                    autoComplete={"off"}
                    fullWidth={true}
                    value={recipeName}
                    onChange={handleChangeRecipeName}
                />
                <br/>
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <Button
                        onClick={props.handleClose}
                        color={"error"}
                        variant={"outlined"}
                        sx={{margin: 1}}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        disabled={isDisabled}
                        onClick={handleDelete}
                        variant={"contained"}
                        color={"secondary"}
                        sx={{margin: 1}}
                    >
                        bestätigen
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}