import axios from "axios"
import Urls from "../config/urls"
import {detailsActions} from "../store/storeIndex";



const ApiDetails = (recipeId, dispatch) => {
    const authToken = localStorage.getItem("token");
    const url = "" + Urls.details + recipeId;

    axios.get(url, {
        id: recipeId,
        headers: (authToken ? {'Authorization': authToken} : undefined)
    })
        .then(res => {
            dispatch(detailsActions.setDetails(res.data));
        })
        .catch(error => {
            console.log(error)
        })
}

export default ApiDetails;