import axios from "axios";
import Urls from "../config/urls";
import {loginActions, snackbarActions} from "../store/storeIndex";


const ApiLogin = (username, password, dispatch, navigate) => {
    axios.post(Urls.login, {
        username: username,
        password: password
    })
        .then(res => {
            localStorage.setItem("token", res.data.token);
            dispatch(loginActions.login());
            navigate("/", {replace: true});
        })
        .catch(error => {
            dispatch(snackbarActions.openSnackbar())
            dispatch(snackbarActions.message("Passwort oder Benutzername inkorrekt"))
            dispatch(snackbarActions.severity("error"));
            console.log(error)
        });
}

export default ApiLogin;