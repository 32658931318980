import {Box, Button, Card, CardContent, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {useDispatch, useSelector} from "react-redux";
import ApiPostVotes from "../../api/apiPostVotes";
import ApiAvailableVotes from "../../api/apiAvailableVotes";
import {useEffect} from "react";
import {votingActions} from "../../store/storeIndex";


const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.primary.light,
        color: theme.text.primary,
        width: "80%",
        margin: "auto",
    },
    flexbox: {
        with: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
    },
}))

export default function VotingCount() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const votesAvailable = useSelector(state => state.voting.available);
    const votesUsed = useSelector(state => state.voting.used);
    const votedRecipes = useSelector(state => state.voting.votes);

    useEffect(() => {
        ApiAvailableVotes(dispatch);
    }, [])

    async function handleSubmit() {
        await ApiPostVotes(dispatch, votedRecipes);
        window.location.reload();
    }

    function handleCancel() {
        dispatch(votingActions.cancelVote())
    }

    function isDisabledFunction () {
        return votesAvailable < 1 || votesUsed === 0;
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <Box className={classes.flexbox}>
                    <Box flexGrow={1}>
                        <Typography>
                            Stimmen übrig: {votesAvailable}
                        </Typography>
                    </Box>
                    <Box flexGrow={4}>
                        <Typography>
                            Stimmen verwendet: {votesUsed}
                        </Typography>
                    </Box>
                    <Box flexGrow={1}>
                        <Button
                            variant={"outlined"}
                            color={"error"}
                            onClick={handleCancel}
                            disabled={isDisabledFunction()}
                        >
                            abbrechen
                        </Button>
                    </Box>
                    <Box flexShrink={6}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            onClick={handleSubmit}
                            disabled={isDisabledFunction()}
                        >
                            Bewertung bestätigen
                        </Button>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}