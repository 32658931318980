import axios from "axios";
import Urls from "../config/urls";
import {snackbarActions} from "../store/storeIndex";


const ApiSubmit = (recipeName, description, ingredients, dispatch, navigate) => {
    const authToken = localStorage.getItem("token");
    let ingredientsList = [];

    // adjust ingredient array to the right format for api
    for (let i = 0; i < ingredients.length; i++) {
        let ingredient = {
            amount: ingredients[i].amount ? ingredients[i].amount : undefined,
            unit: ingredients[i].unit ? ingredients[i].unit : undefined,
            ingredient_name: ingredients[i].ingredientName,
            ingredient_id: ingredients[i].id ? ingredients[i].id : undefined
        };
        ingredientsList.push(ingredient)
    }

    axios.post(Urls.postRecipe, {
            recipe_name: recipeName,
            description: description,
            ingredients: ingredientsList,
        },
        {headers: {'Authorization': authToken}}
    )
        .then(res => {
            const recipeId = res.data.recipe_id;
            // jump to deetails page after submitting recipe
            navigate("/details/" + recipeId);
            dispatch(snackbarActions.openSnackbar());
            dispatch(snackbarActions.message("Rezept erfolgreich geteilt"))
            dispatch(snackbarActions.severity("success"))
        })
        .catch(error => {
            dispatch(snackbarActions.openSnackbar());
            dispatch(snackbarActions.message("Fehler beim teilen des Rezepts"))
            dispatch(snackbarActions.severity("error"))
            console.log(error)
        })
}

export default ApiSubmit;