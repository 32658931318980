import {Box, IconButton} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    edit: {
        color: theme.text.primary,
    }
}));

const RecipesTableEdit = (props) => {
    const editUrl = "/recipe/" + props.id;
    const classes = useStyles();

    const handleClick = () => {
        props.setOpen(true);
        props.setDeleteRecipeId(props.id);
        props.setDeleteRecipeName(props.recipeName);
    };

    return(
        <Box>
            <IconButton
                className={classes.edit}
                href={editUrl}
            >
                <EditIcon/>
            </IconButton>
            <IconButton
                className={classes.edit}
                onClick={handleClick}
            >
                <DeleteIcon/>
            </IconButton>
        </Box>
    )
}
export default RecipesTableEdit;