import {Box, Typography} from "@mui/material";


function User() {
    return (
        <Box sx={{
            textAlign: "center",
            width: "30%",
            margin: "auto",
        }}>
            <Typography variant={"h3"} gutterBottom>User Profil</Typography><br/>
            <Typography variant={"h4"} textAlign={"left"}>Optionen:</Typography><br/>
            <Typography variant={"h6"}>Passwort ändern</Typography><br/>
            <Typography variant={"h6"}>E-Mail ändern</Typography><br/>
        </Box>
    );
}

export default User