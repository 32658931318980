import {Box, Button, FormControl, IconButton, Input, InputAdornment, InputLabel, Typography} from "@mui/material"
import TextFieldStyled from "../../components/textFieldStyled/textFieldStyled";
import {Visibility, VisibilityOff} from "@mui/icons-material"
import {makeStyles} from "@mui/styles"
import {useState} from "react";
import {useDispatch} from "react-redux";
import ApiLogin from "../../api/apiLogin";
import LoginSchema from "../../validations/loginValidation/loginValidation";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.text.primary
    },
    textButton: {
        color: theme.palette.secondary.main,
        marginTop: "5px",
        marginBottom: "25px"
    },
    errorMessage: {
        color: theme.palette.error.light,
        fontSize: "14pt"
    },
    password: {
        color: theme.text.primary
    }
}))

export default function LogIn() {
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            username: "",
            password: ""
        },
        validationSchema: LoginSchema,
        onSubmit: async (values) => {
            await ApiLogin(values.username, values.password, dispatch, navigate);
        }
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box
            sx={{
                width: "55%",
                textAlign: "left",
                margin: "auto",
            }}
            component={"form"}
            noValidate
            autoComplete={"off"}
            onSubmit={formik.handleSubmit}
        >
            <Typography
                variant={"h3"}
                textAlign={"center"}
                gutterBottom
            >
                Anmelden:
            </Typography>
            <TextFieldStyled
                fullWidth={true}
                id={"username"}
                label={"Benutzername"}
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            {formik.touched.username && formik.errors.username ?
                <Typography className={classes.errorMessage}>
                    {formik.errors.username}
                </Typography> : null}

            <FormControl
                fullWidth={true}
                variant={"standard"}
                margin={"normal"}
            >
                <InputLabel>
                    Passwort
                </InputLabel>
                <Input
                    id={"password"}
                    className={classes.password}
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                className={classes.icon}
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            {formik.touched.password && formik.errors.password ?
                <Typography className={classes.errorMessage}>
                    {formik.errors.password}
                </Typography> : null}

            <Button
                className={classes.textButton}
                href={"newuser"}
            >Neues Profil erstellen</Button> <br/>
            <Button
                type={"submit"}
                variant={"contained"}
                color={"secondary"}
            >anmelden</Button>
        </Box>
    )
}