import {Box, Card, CardContent, IconButton} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {makeStyles} from "@mui/styles"


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.light,
        color: theme.text.primary,
    },
    icon: {
        color: theme.text.primary,
    },
    ingredientsList: {
        flexDirection: "column"
    },
    flexbox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "left",
    },
    text: {
        color: theme.text.primary,
    }
}))


export default function IngredientsDisplay(props) {
    const classes = useStyles();

    function generateId(ingredient) {
        const key = "" + ingredient.amount + ingredient.unit + ingredient.ingredientName;
        return (key)
    }

    return (
        <Card className={classes.root}>
            <CardContent>
                {props.value.map((ingredient) => {
                    return (
                        <Box
                            className={classes.ingredientsList}
                            key={generateId(ingredient)}
                        >
                            <Box
                                className={classes.flexbox}
                            >
                                <Box flexGrow={2}>
                                    {ingredient.amount}
                                </Box>
                                <Box flexGrow={4}>
                                    {ingredient.unit}
                                </Box>
                                <Box flexGrow={3}>
                                    {ingredient.ingredientName}
                                </Box>
                                <Box>
                                    <IconButton
                                        className={classes.icon}
                                        aria-label={"delete"}
                                        onClick={() => props.onDelete(ingredient)}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                    <IconButton
                                        className={classes.icon}
                                        aria-label={"edit"}
                                        onClick={() => props.onEdit(ingredient)}
                                    >
                                        <EditIcon/>
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    )
                })}
            </CardContent>
        </Card>
    )
}