import axios from "axios";
import Urls from "../config/urls";
import {recipeActions, snackbarActions} from "../store/storeIndex";


const ApiRecipes = (startingIndex, pageSize, dispatch) => {
    axios.get(Urls.recipes, {
        starting_index: startingIndex,
        page_size: pageSize
    })
        .then(res => {
            dispatch(recipeActions.loadData(res.data));
        })
        .catch(error => {
            dispatch(snackbarActions.openSnackbar());
            dispatch(snackbarActions.message("Rezepte konnten nicht geladen werden"))
            dispatch(snackbarActions.severity("error"))
            console.log(error.response);
        });
}

export default ApiRecipes