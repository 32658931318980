import {Box, Card, CardContent, IconButton, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import ApiPostVotes from "../../api/apiPostVotes";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux"


const useStyles = makeStyles((theme) => ({
    flexbox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    root: {
        backgroundColor: theme.palette.primary.light,
        color: theme.text.primary,
    },
    icon: {
        color: theme.text.primary,
        size: "80pt"
    },
    text: {
        color: theme.text.primary,
        fontSize: "17pt"
    }
}))

export default function VotingCard(props) {
    const classes = useStyles()
    const ratedRecipes = useSelector(state => state.recipesRated.recipeIds)
    const votesAvailable = useSelector(state => state.voting.available);
    const dispatch = useDispatch();

    const isEnabledFunction = (id) => {
        let isDisabled = false;
        if (ratedRecipes.recipe_ids) {
            for (let i = 0; i < ratedRecipes.recipe_ids.length; i++) {
                if (id === ratedRecipes.recipe_ids[i]) {
                    isDisabled = true;
                }
            }
        }
        if(votesAvailable === 0){
            isDisabled = true;
        }
        return isDisabled;
    }

    function subtractPoint() {
        const votedRecipe = [{
            recipe_id: props.recipeId,
            vote: -1,
        }]
        ApiPostVotes(dispatch, votedRecipe);
        window.location.reload();
    }

    function addPoint() {
        const votedRecipe = [{
            recipe_id: props.recipeId,
            vote: 1,
        }]
        ApiPostVotes(dispatch, votedRecipe);
        window.location.reload();
    }

    return (
        <Card className={classes.root}>
            <CardContent>
                <Box className={classes.flexbox}>
                    <Box flexGrow={0.5}>
                        <Typography className={classes.text}>
                            Bewertung:
                        </Typography>
                    </Box>
                    <Box flexGrow={1}>
                        <Typography className={classes.text}>
                            Punktzahl: {props.voting}
                        </Typography>
                    </Box>
                    <Box flexShrink={1}>
                        <IconButton
                            disabled={isEnabledFunction(props.recipeId)}
                            className={classes.icon}
                            aria-label={"downvote"}
                            onClick={subtractPoint}
                        >
                            <RemoveCircleTwoToneIcon fontSize={"large"}/>
                        </IconButton>
                    </Box>
                    <Box flexGrow={3}>
                        <IconButton
                            disabled={isEnabledFunction(props.recipeId)}
                            className={classes.icon}
                            aria-label={"upvote"}
                            onClick={addPoint}
                        >
                            <AddCircleTwoToneIcon fontSize={"large"}/>
                        </IconButton>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}