import {makeStyles} from "@mui/styles";
import {TextField} from "@mui/material";


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiInputBase-root": {
            color: theme.text.primary,
        }
    }
}))

export default function TextFieldStyled(props) {
    const classes = useStyles();
    return (
        <TextField
            {...props}
            className={classes.root}
            variant={"standard"}
            margin={"normal"}
        />
    );
}
