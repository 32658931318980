import {Box, Typography} from "@mui/material";
import RecipeTable from "../../components/recipeTable/recipesTable"
import VotingCount from "../../components/votingCount/votingCount";
import {useSelector} from "react-redux";
import DeleteDialog from "../../components/deleteDialog/deleteDialog";
import {useState} from "react";


export default function ShowRecipes() {
    const isLoggedIn = useSelector((state => state.login.loginStatus))
    const[open, setOpen] = useState(false);
    const[deleteRecipeId, setDeleteRecipeId] = useState("");
    const[deleteRecipeName, setDeleteRecipeName] = useState("");

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Box>
            <Typography
                variant={"h3"}
                textAlign={"center"}
                gutterBottom
            >Entdecke neue Rezepte</Typography>
            {isLoggedIn &&
                <VotingCount/>}
            <p/>
            <RecipeTable
                setOpen={setOpen}
                setDeleteRecipeName={setDeleteRecipeName}
                setDeleteRecipeId={setDeleteRecipeId}
            />
            <DeleteDialog
                open={open}
                handleClose={handleClose}
                recipeId={deleteRecipeId}
                recipeName={deleteRecipeName}
            />
        </Box>
    );
}

